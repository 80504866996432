/* Navbar.css */
body {
    font-family: Arial, sans-serif;
  }
  
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: flex-end;
    background-color: #FF5733;
    padding: 1rem 2rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #067df0; /* Clashing blue color */
    font-size: 1.2rem;
    border: 5px solid #067df0; /* Clashing blue color */
    padding: 0.5rem;
    margin-left: 1rem;
    transition: all 0.3s ease;
  }
  
  .nav-link:hover {
    background-color: #067df0; /* Clashing blue color */
    color: #FFFFFF;
    cursor: pointer;
  }
  
  
  