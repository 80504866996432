.activity-div {
    text-align: left;
    padding-left: 15%;
  }
  
  .activity-div h1 {
    font-family: 'Arial', sans-serif; /* Change this to the font you want */
    font-size: 55px; /* Change this to the size you want */

  }


  .activity-button {
    color: white;
    background-color: #FF7F00;
    border: 2px solid green;
    border-color: green;
    border-radius: 5px;
    padding: 10px;
    font-size: 15px;
    position:relative;
  }


  .activity-button::after {
    content:'';
    position:absolute;
    background-color:green;
    border-radius: 5px;
    top:8px;
    left:8px;
    right:-8px;
    bottom:-8px;
    z-index:-1;
  }