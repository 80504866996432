/* Header.css */

.greeting {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    animation: scroll 2s linear infinite;
  }
  

@keyframes scroll {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    50% {
      transform: translateY(-50%);
      opacity: 0;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }
  
  